import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHeader from "../components/pageheader"
import { useEmailForm } from "../util/useEmailForm"
import PageBody from "../components/pagebody"
const contactFormURL = process.env.CONTACT_FORM_URL

const ContactPage = ({ location }) => {
  const {
    submitHandler,
    register,
    submitting,
    formErrors,
    submitError,
  } = useEmailForm({
    apiUrl: contactFormURL,
    redirectUrl: "/contact-sent",
  })

  return (
    <Layout currPage="contact" location={location}>
      <Seo title="Contact us" />
      <PageHeader
        pageTitle="Contact us"
        breadCrumbs={[{ caption: "home", link: "/" }, { caption: "contact" }]}
      />
      <PageBody>
        <div className="contact">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="contact-form">
                  <form onSubmit={submitHandler}>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <input
                          {...register("site", {})}
                          type="hidden"
                          className="form-control"
                          value="chinesetutor.org"
                        />
                        <input
                          {...register("name", {
                            required: "This field is required.",
                          })}
                          type="text"
                          className={`form-control${
                            formErrors?.name ? " formFieldError_border" : ""
                          }`}
                          placeholder="Your Name"
                        />
                        <span className="formFieldError">
                          {formErrors?.name && formErrors.name.message}
                        </span>
                      </div>
                      <div className="form-group col-md-6">
                        <input
                          {...register("email", {
                            required: "This field is required.",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Please provide a valid email.",
                            },
                          })}
                          className={`form-control${
                            formErrors?.email ? " formFieldError_border" : ""
                          }`}
                          placeholder="Your Email"
                          type="text"
                        />
                        <span className="formFieldError">
                          {formErrors?.email && formErrors.email.message}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        {...register("subject", {
                          required: "This field is required.",
                        })}
                        type="text"
                        className={`form-control${
                          formErrors?.subject ? " formFieldError_border" : ""
                        }`}
                        placeholder="Subject"
                      />
                      <span className="formFieldError">
                        {formErrors?.subject && formErrors.subject.message}
                      </span>
                    </div>
                    <div className="form-group">
                      <textarea
                        {...register("message", {
                          required: "This field is required.",
                        })}
                        className={`form-control${
                          formErrors?.message ? " formFieldError_border" : ""
                        }`}
                        rows="6"
                        placeholder="Message"
                      ></textarea>
                      <span className="formFieldError">
                        {formErrors?.message && formErrors.message.message}
                      </span>
                    </div>
                    <div className="btndiv">
                      <button
                        className="btn"
                        type="submit"
                        disabled={submitting}
                      >
                        {submitting ? "sending.." : "Send Message"}
                      </button>
                    </div>
                  </form>
                  <div className="formSubmitError">{submitError}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageBody>
    </Layout>
  )
}

export default ContactPage
