import { useStaticQuery, graphql } from "gatsby"
export const useSiteMetadata = () => {
    const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
                description
                ogDescription
                keywords
                siteUrl
              }
            }
          }
        `
    );
    return site.siteMetadata
};

