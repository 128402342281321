import * as React from "react"
import PropTypes from "prop-types"

const PageHeader = ({ pageTitle, breadCrumbs }) => {
  return (
    <div className="page-header">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>{pageTitle}</h2>
          </div>
          <div className="col-12">
            {breadCrumbs?.map((breadCrumb, index) => {
              return breadCrumb.link ? (
                <a key={index} href={breadCrumb.link}>
                  {breadCrumb.caption}
                </a>
              ) : (
                <span key={index}>{breadCrumb.caption}</span>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

PageHeader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
}

PageHeader.defaultProps = {}

export default PageHeader
